import {am} from "@/i18/lang/am";
import {ru} from "@/i18/lang/ru";
import {en} from "@/i18/lang/en";

const messages = {
    am,
    en,
    ru
}

export {messages}

const ru = {
        welcome: 'Добро пожаловать в наш онлайн магазин',
        search: 'Поиск',
        assortment: 'АССОРТИМЕНТ',
        mainPage: 'Главная страница',
        newPrice: 'Новая цена',
        buy: 'Купить',
        offers: 'СЕГОДНЯШНИЕ ПРЕДЛОЖЕНИЯ',
        bestSeller: 'БЕСТСЕЛЛЕР',
        news: 'НОВИНКИ',
        bestSellers: 'САМЫЕ ПОПУЛЯРНЫЕ',
        freeDeliveryText: 'Бесплатная доставка по территории Республики Армения для всех заказов на сумму 5000 драмов и выше.',
        quantity: 'По количеству',
        products_page_6: '6 штук',
        products_page_9: '9 штук',
        products_page_12: '12 штук',
        asc: 'Низшая цена',
        desc: 'Самая высокая цена',
        price: 'По цене',
        selectType: 'Выбрать по типу',
        selectPrice: 'Выбрать по цене',
        selectBrand: 'Выбрать по бренду',
        selectSize: 'Выбрать по размеру',
        selectColor: 'Выбрать по цвету',
        mostViewed: 'Самые просматриваемые',
        aboutUs: 'ПРО НАС',
        newAssortment: 'Новая коллекция',
        forYou: 'только для вас',
        unique_text1: 'Оригинальные и высококачественные украшения, которые',
        unique_text2: ' удовлетворят даже самых требовательных покупателей.',
        freeDelivery: 'Бесплатная доставка',
        style: 'Изысканный ассортимент',
        styleText: 'Наши украшения - это стильный и уникальный подарок для ваших близких.',
        onlineService: 'Онлайн-сервис',
        onlineServiceText: 'Мы поддерживаем наших клиентов с любовью. Делайте покупки у нас.',
        about: 'Про нас ',
        orderPage: 'Страница заказа',
        wishList: 'Избранные',
        shoppingCart: 'Корзина',
        allProducts: 'Все продукты',
        product: 'Продукт',
        max_price: 'Макс цена',
        min_price: 'Мин цена',
        help_contact: 'Помощь и контакты',
        color: 'Цвет',
        size: 'Размер',
        is_available: 'Доступно',
        not_available: 'Недоступно',
        add_to_cart : 'Добавить в корзину',
        description: 'ОПИСАНИЕ',
        characteristics: 'ХАРАКТЕРИСТИКИ',
        name: 'Имя',
        phone: 'Телефон',
        your_order: 'Ваш заказ',
        address: 'Адрес',
        postal_code: 'Почтовый Индекс',
        total_cost: 'Общая Стоимость',
        currency: '֏',
        piece: 'Кусок',
        necessarily: 'Обязательно',
        incorrect_format: 'Неправильный формат',
        shipping_Information: 'Информация О Доставке',
        confirm_order: 'Подтвердите заказ',
        congratulations_registered: 'Поздравляю, ваш заказ был зарегистрирован:',
        will_contact_you_within_a_very_short_period_of_time: 'Мы свяжемся с вами в течение очень короткого периода времени:',
        go_back_to_the_list: 'Вернуться на главную страницу',
        buy_now: 'Купить сейчас',
        continue_shopping: 'Продолжить покупки',
        to_order: 'Заказать',
        shopping_cart: 'Корзина',
        cart_empty: 'Корзина пуста',
        like: 'нравиться',
        new: 'Новый',
        quick_view: 'Быстрый просмотр',
        action_remove_specified_element: 'Это действие удалит указанный элемент из вашего',
        from_basket: 'из корзины',
        from_favorites_list: 'из списка избранного',
        yes: 'Да',
        no: 'Нет',
        choose_color: 'Выберите цвет',
        choose_size: 'Выберите размер',
        added: 'Добавлено',
        deleted: 'Удалено',
        select_quantity: 'Выберите количество',
        product_cart: 'Товар в корзине',
        similar_products: 'Похожие товары',
        nothing_was_found_result_query: 'В результате запроса ничего не найдено',
        there_are_still_products: 'Раздел пуст',
        view_all: 'Посмотреть все',
        choose: 'Выбирать',
        assortment_watches: 'Стильные часы',
        assortment_jewelry: 'Стильные украшения',
        sale: 'Начать покупки',
        are_you_sure: 'Вы уверены',
        contactUs: 'Связаться с нами',
        ContactInformation: 'Контактная информация Мы здесь, чтобы помочь!',
        callUs: 'Позвоните нам',
        emailUs: 'Свяжитесь с нами по электронной почте',
}

export {ru}
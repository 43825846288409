const en = {
        welcome: 'Welcome to our online store',
        search: 'Search',
        assortment: 'ASSORTMENT',
        mainPage: 'Home page',
        newPrice: 'New price',
        buy: 'Buy',
        offers: "TODAY'S OFFERS",
        bestSeller: 'BESTSELLER',
        news: 'NEW PRODUCTS',
        bestSellers: 'TOP RATED',
        freeDeliveryText: 'Free shipping on the territory of the Republic of Armenia for all orders of  5000 AMD or above.',
        quantity: 'By quantity',
        products_page_6: '6 pieces',
        products_page_9: '9 pieces',
        products_page_12: '12 pieces',
        asc: 'Lowest Price',
        desc: 'Highest Price',
        price: 'By price',
        selectType: 'Select by type',
        selectPrice: 'Select by price',
        selectBrand: 'Select by brand',
        selectSize: 'Select by size',
        selectColor: 'Select by color',
        mostViewed: 'Most viewed',
        aboutUs: 'ABOUT US',
        newAssortment: 'New collection',
        forYou: 'just for you',
        unique_text1: 'Original and high-quality jewelry that',
        unique_text2: 'will satisfy even the most demanding customers.',
        freeDelivery: 'Free shipping',
        style: 'Exquisite assortment',
        styleText: 'Our jewelry is a stylish and unique gift for your loved ones.',
        onlineService: 'Online service',
        onlineServiceText: "We support our customers with love. Let's do shopping together.",
        about: 'About us',
        orderPage: 'Order page',
        wishList: 'Wishlist',
        shoppingCart: 'Shopping Cart',
        allProducts: 'All products',
        product: 'Product',
        max_price: 'Max price',
        min_price: 'Min price',
        help_contact: 'Help & Contact',
        color: 'Color',
        size: 'Size',
        is_available: 'Is available',
        not_available: 'Not available',
        add_to_cart : 'Add to cart',
        description: 'DESCRIPTION',
        characteristics: 'CHARACTERISTICS',
        name: 'Name',
        phone: 'Phone',
        your_order: 'Your order',
        address: 'Address',
        postal_code: 'Postal code',
        total_cost: 'Total Cost',
        currency: '֏',
        piece: 'Piece',
        necessarily: 'Necessarily',
        incorrect_format: 'Incorrect format',
        shipping_Information: 'Shipping Information',
        confirm_order: 'Confirm the order',
        congratulations_registered: 'Congratulations, your order has been registered:',
        will_contact_you_within_a_very_short_period_of_time: 'We will contact you within a very short period of time:',
        go_back_to_the_list: 'Go back to home page',
        buy_now: 'Buy now',
        continue_shopping: 'Continue shopping',
        to_order: 'To order',
        shopping_cart: 'Shopping cart',
        cart_empty: 'The basket is empty',
        like: 'Like',
        new: 'New',
        quick_view: 'Quick View',
        action_remove_specified_element: 'This action will remove the specified element from your',
        from_basket: 'from the basket',
        from_favorites_list: 'from the favorites list',
        yes: 'Yes',
        no: 'No',
        choose_color: 'Choose a color',
        choose_size: 'Choose a size',
        added: 'Added',
        deleted: 'Deleted',
        select_quantity: 'Select the quantity',
        product_cart: 'The product is in the cart',
        similar_products: 'Similar products',
        nothing_was_found_result_query: 'Nothing was found as a result of the query',
        there_are_still_products: 'The section is empty',
        view_all: 'View all',
        choose: 'Choose',
        assortment_watches: 'Stylish watches',
        assortment_jewelry: 'Stylish jewelry',
        sale: 'Start shopping',
        are_you_sure: 'Are you sure',
        contactUs: 'Contact us',
        ContactInformation: 'Contact Information We\'re here to help!',
        callUs: 'Call us',
        emailUs: 'Email us',

}
export {en}
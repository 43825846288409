const am = {
        welcome: 'Բարի գալուստ մեր online խանութ',
        search: 'Փնտրել',
        assortment: 'ՏԵՍԱԿԱՆԻ',
        mainPage: 'Գլխավոր էջ',
        newPrice: 'Նոր գին',
        buy: 'Գնել',
        offers: 'ՕՐՎԱ ԱՌԱՋԱՐԿՆԵՐԸ',
        bestSeller: 'ԼԱՎԱԳՈՒՅՆ ՎԱՃԱՌՔ',
        news: 'ՆՈՐՈՒՅԹՆԵՐ',
        bestSellers: 'ԲԱՐՁՐ ԳՆԱՀԱՏԱԿԱՆՈՎ',
        freeDeliveryText: 'Անվճար առաքում ՀՀ տարածքում՝ 5000 դրամ և ավել բոլոր պատվերների համար։',
        quantity: 'Ըստ քանակի',
        products_page_6: '6 հատ',
        products_page_9: '9 հատ',
        products_page_12: '12 հատ',
        asc: 'Նվազագույն Գինը',
        desc: 'Ամենաբարձր Գինը',
        price: 'Ըստ գնի',
        selectType: 'Ընտրել ըստ տեսակի',
        selectPrice: 'Ընտրել ըստ գնի',
        selectBrand: 'Ընտրել ըստ բրենդի',
        selectSize: 'Ընտրել ըստ չափի',
        selectColor: 'Ընտրել ըստ գույնի',
        mostViewed: 'Հաճախ հարցվողները',
        aboutUs: 'ՄԵՐ ՄԱՍԻՆ',
        newAssortment: 'Նոր տեսականի',
        forYou: 'հենց քեզ համար',
        unique_text1: 'Ինքնատիպ և որակյալ զարդեր,որոնք',
        unique_text2: 'կգոհացնեն նույնիսկ ամենաքմահաճ հաճախորդին',
        freeDelivery: 'Անվճար առաքում',
        style: 'Նրբաոճ տեսականի',
        styleText: 'Մեր զարդերը նորաձև և յուրօրինակ նվեր են Ձեր սիրելիների համար։',
        onlineService: 'Առցանց սպասարկում',
        onlineServiceText: 'Մենք սիրով աջակցում ենք մեր հաճախորդներին: Եկեք գնումներ կատարենք միասին:',
        about: 'Մեր մասին',
        orderPage: 'Պատվերի էջ',
        wishList: 'Հավանումներ',
        shoppingCart: 'Զամբյուղ',
        allProducts: 'Բոլորը',
        product: 'Ապրանք',
        max_price: 'Մաքսիմալ գին',
        min_price: 'Մինիմալ գին',
        help_contact: 'Օգնություն և Կապ',
        color: 'Գույն',
        size: 'Չափս',
        is_available: 'Հասանելի է',
        not_available: 'Հասանելի չէ',
        add_to_cart : 'Ավելացնել զամբյուղ',
        description: 'ՆԿԱՐԱԳՐՈՒԹՅՈՒՆ',
        characteristics: 'ԲՆՈՒԹԱԳԻՐ',
        name: 'Անուն',
        phone: 'Հեռախոսահամար',
        your_order: 'Ձեր պատվերը',
        address: 'Հասցե',
        postal_code: 'Փոստային Ինդեքս',
        total_cost: 'Ընդհանուր Արժեքը՝',
        currency: '֏',
        piece: 'հատ',
        necessarily: 'Պարտադիր է',
        incorrect_format: 'Սխալ ֆորմատ',
        shipping_Information: 'Առաքման տվյալներ',
        confirm_order: 'Հաստատել պատվերը',
        congratulations_registered: 'Շնորհավորում ենք Ձեր պատվերը գրանցվել է:',
        will_contact_you_within_a_very_short_period_of_time: 'Շատ կարճ ժամանակահատվածում մենք ձեզ հետ կապ կհաստատենք:',
        go_back_to_the_list: 'Վերադառնալ գլխավոր էջ',
        buy_now: 'Գնել հիմա',
        continue_shopping: 'Շարունակել գնումները',
        to_order: 'Պատվիրել',
        shopping_cart: 'Զամբյուղ',
        cart_empty: 'Զամբյուղը դատարկ է',
        like: 'Հավանել',
        new: 'Նոր',
        quick_view: 'Արագ դիտում',
        action_remove_specified_element: 'Այս գործողությունը կհեռացնի նշված ապրանքը ձեր',
        from_basket: 'զամբյուղից',
        from_favorites_list: 'նախընտրելիների ցանկից',
        yes: 'Այո',
        no: 'Ոչ',
        choose_color: 'Ընտրեք գույնը',
        choose_size: 'Ընտրեք չափսը',
        added: 'Ավելացվել է',
        deleted: 'Ջնջվել է',
        select_quantity: 'Ընտրեք քանակը',
        product_cart: 'Ապրանքը զամբյուղում է',
        similar_products: 'Նմանատիպ ապրանքներ',
        nothing_was_found_result_query: 'Հարցման արդյունքում ոչինչ չի գտնվել',
        there_are_still_products: 'Բաժինը դատարկ է',
        view_all: 'Դիտել բոլորը',
        choose: 'Ընտրել',
        assortment_watches: 'Նորավոճ ժամացույցներ',
        assortment_jewelry: 'Նորավոճ զարդեր',
        sale: 'Սկսել shopping',
        are_you_sure: 'Համոզվա՞ծ եք',
        contactUs: 'Կապվեք մեզ հետ',
        ContactInformation: 'Կոնտակտային տվյալներ Մենք այստեղ ենք օգնելու համար:',
        callUs: 'Զանգիր մեզ',
        emailUs: 'Ուղարկել մեզ էլ',

}

export {am}